import React from 'react';
import { products } from './MainmenuContentsConfig';
import './MainmenuContents.css';

const MainmenuContents = () => {
    const singleItems = products.single;
    const bundleItems = products.bundle;

    const sectionStyle = {
      backgroundImage: `url(/images/coffeemore3.jpg)`,
      backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundAttachment: 'fixed' // パララックス効果を適用
    };

    return (
        <div>
            <div className="parallax" style={sectionStyle}>
                <div className="overlay"></div>
                <div className="content">
                    <div className="mainmenu-contents">
                        <h1 className="main-title">Main Menu</h1>
                        <div className="description-container">
                            <p>ここに買い方を説明する文章や画像を挿入できます。</p>
                        </div>
                        <h2 className="sub-title">お好みのグラムで購入</h2>
                        <div className="container single">
                            {singleItems.map((product, index) => (
                                <div key={index} className="product-box single">
                                    <div className="product-name">{product.name}</div>
                                    <img src={product.image} alt={product.name} className="product-image" />
                                    <div className="product-weight">{product.weight}</div>
                                    <div className="product-price">{product.price}</div>
                                    <div className="product-description">{product.description.split('\n').map((line, index) => (
                                        <p key={index}>{line}</p>
                                    ))}</div>
                                    <a href={product.link} target="_blank" rel="noopener noreferrer" className="product-button">
                                        商品注文
                                    </a>
                                </div>
                            ))}
                        </div>
                        <h2 className="sub-title">まとめ買い</h2>
                        <div className="container bundle">
                            {bundleItems.map((product, index) => (
                                <div key={index} className="product-box bundle">
                                    <div className="product-name">{product.name}</div>
                                    <img src={product.image} alt={product.name} className="product-image" />
                                    <div className="product-weight">{product.weight}</div>
                                    <div className="product-price">{product.price}</div>
                                    <div className="product-catchphrase">
                                        {product.catchphrase.split('\n').map((line, index) => (
                                            <p key={index}>{line}</p>
                                        ))}
                                    </div>
                                    <div className="product-description">
                                        {product.description.split('\n').map((line, index) => (
                                            <p key={index}>{line}</p>
                                        ))}
                                    </div>
                                    <a href={product.link} target="_blank" rel="noopener noreferrer" className="product-button">
                                        商品注文
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MainmenuContents;
