export const products = {
    single: [
        {
            name: "エチオピア 50g 浅煎 グジ アナソラ アナエロビック",
            image: '/images/menu/coffee1-50.jpg',
            weight: "50g",
            price: "¥450 税込",
            description: "ワイン、マスカットフルーツ感、\n華やかなアロマシロップのような甘い余韻、\n個性的で大変香りのよい上品なコーヒーです。",
            link: "https://hidekicc.base.shop/items/80821279"
        },
        {
            name: "ケニア 50g 浅煎 カグモイニ コーヒーファクトリー",
            image: '/images/menu/coffee2-50.jpg',
            weight: "50g",
            price: "¥450 税込",
            description: "ピーチやマンゴーのようなフルーツ感、\nジューシー、キャラメルシロップのような甘味。",
            link: "https://hidekicc.base.shop/items/80822467"
        },
        {
            name: "コスタリカ 50g 中煎 カンデリージャ ハニー",
            image: '/images/menu/coffee3-50.jpg',
            weight: "50g",
            price: "¥390 税込",
            description: "香ばしいナッツの感じ、\nブラウンシュガー，完熟オレンジの甘味、\nロングアフターテイスト。",
            link: "https://hidekicc.base.shop/items/80822557"
        },
        {
            name: "ブラジル 50g 中煎 カルモ",
            image: '/images/menu/coffee4-50.jpg',
            weight: "50g",
            price: "¥390 税込",
            description: "マイルドな口当たり、\n酸味、苦味、ともに程よく、\nバランスの取れた仕上がり。",
            link: "https://hidekicc.base.shop/items/80823248"
        },
        {
            name: "グァテマラ 50g 深煎 エル･インヘルト農園 ブルボン",
            image: '/images/menu/coffee5-50.jpg',
            weight: "50g",
            price: "¥465 税込",
            description: "重厚なカカオを思わせる、\nスイートチョコ，赤ワイン、\nローストナッツ。",
            link: "https://hidekicc.base.shop/items/80823299"
        },
        {
            name: "インドネシア 50g 深煎マンデリン G-1 アルールバダ",
            image: '/images/menu/coffee6-50.jpg',
            weight: "50g",
            price: "¥390 税込",
            description: "カカオ、ビターチョコの甘味、\nエキゾチックアーシー感、アプリコット。",
            link: "https://hidekicc.base.shop/items/80821279"
        },
    ],
    bundle: [
        {
            name: "１ヶ月分! エチオピア 500g 浅煎 グジ アナソラ アナエロビック",
            image: '/images/menu/coffee1-500.jpg',
            weight: "500g",
            price: "¥4,400 税込",
            catchphrase: "お得な500gをご用意いたしました！（250gx2袋）",
            description: "ワイン、マスカットフルーツ感、華やかなアロマ、\nシロップのような甘い余韻。\n個性的で大変香りのよい上品なコーヒーです。",
            link: "https://hidekicc.base.shop/items/86337248"
        },
        {
            name: "１ヶ月分！ケニア 500g 浅煎 カグモイニ　コーヒーファクトリー",
            image: '/images/menu/coffee2-500.jpg',
            weight: "500g",
            price: "¥4,400 税込",
            catchphrase: "お得な500gをご用意いたしました！（250gx2袋）",
            description: "ピーチやマンゴーのようなフルーツ感，\nジューシー、キャラメルシロップのような甘味。",
            link: "https://hidekicc.base.shop/items/86337486"
        },
        {
            name: "１ヶ月分！コスタリカ 500g 中煎 カンデリージャ ハニー",
            image: '/images/menu/coffee3-500.jpg',
            weight: "500g",
            price: "¥3,800 税込",
            catchphrase: "お得な500gをご用意いたしました！（250gx2袋）",
            description: "香ばしいナッツの感じ、\nブラウンシュガー，完熟オレンジの甘味、\nロングアフターテイスト。",
            link: "https://hidekicc.base.shop/items/86337630"
        },
        {
            name: "１ヶ月分！ブラジル 500g 中煎 カルモ",
            image: '/images/menu/coffee4-500.jpg',
            weight: "500g",
            price: "¥3,800 税込",
            catchphrase: "お得な500gをご用意いたしました！（250gx2袋）",
            description: "マイルドな口当たり、\n酸味、苦味、ともに程よく\nバランスの取れた仕上がり。",
            link: "https://hidekicc.base.shop/items/86337719"
        },
        {
            name: "１ヶ月分！グァテマラ 500g 深煎 エル･インヘルト農園 ブルボン",
            image: '/images/menu/coffee5-500.jpg',
            weight: "500g",
            price: "¥4,550 税込",
            catchphrase: "お得な500gをご用意いたしました！（250gx2袋）",
            description: "重厚なカカオを思わせる、\nスイートチョコ，赤ワイン、\nローストナッツ。",
            link: "https://hidekicc.base.shop/items/86337917"
        },
        {
            name: "１ヶ月分！インドネシア 500g 深煎マンデリン G-1 アルールバダ",
            image: '/images/menu/coffee6-500.jpg',
            weight: "500g",
            price: "¥3,800 税込",
            catchphrase: "お得な500gをご用意いたしました！（250gx2袋）",
            description: "カカオ、ビターチョコの甘味、\nエキゾチックアーシー感、アプリコット。",
            link: "https://hidekicc.base.shop/items/86337959"
        },
    ]
};
