import React, { useState } from 'react';
import { pickupProducts } from './PickupContentsConfig';
import './PickupContents.css';

function PickupContents() {
    const [isAnimating, setIsAnimating] = useState(null);

    const handleMouseOver = (id) => {
        setIsAnimating(id);
    };

    const handleMouseOut = () => {
        setIsAnimating(null);
    };

    return (
        <div className="pickup-contents">
            {pickupProducts.map(pickup => (
                <div key={pickup.id} className="pickup-card"
                     onMouseOver={() => handleMouseOver(pickup.id)}
                     onMouseOut={handleMouseOut}>
                    <div className="pickup-animation-layer">
                        {isAnimating === pickup.id 
                            ? <img src="/images/coffeeanime.gif" alt="Coffee Animation" />
                            : <img src="/images/coffeeanime.png" alt="Static Coffee" />
                        }
                    </div>
                    <div className="pickup-content-layer">
                        <img src={pickup.image} alt={pickup.name} className="pickup-image" />
                        <p className="pickup-catchphrase">{pickup.catchphrase}</p>
                        <h3 className="pickup-name">{pickup.name}</h3>
                        <p className="pickup-price">{pickup.price}</p>
                        <a href={pickup.link} target="_blank" rel="noopener noreferrer" className="view-shop-button">
                            ショップを見る
                        </a>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default PickupContents;
