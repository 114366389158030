export const pickupProducts = [
    {
        id: 1,
        name: 'エチオピア 500g 浅煎 グジ アナソラ アナエロビック',
        image: '/images/menu/coffee1-500.jpg',
        price: '¥4,400 税込',
        catchphrase: '１ヶ月分!',
        link: 'https://hidekicc.base.shop/items/86337248'
    },
    {
        id: 2,
        name: 'ケニア 500g 浅煎 カグモイニ　コーヒーファクトリー',
        image: '/images/menu/coffee2-500.jpg',
        price: '¥4,400 税込',
        catchphrase: '１ヶ月分!',
        link: 'https://hidekicc.base.shop/items/86337486'
    },
    {
        id: 3,
        name: 'コスタリカ 500g 中煎 カンデリージャ ハニー',
        image: '/images/menu/coffee3-500.jpg',
        price: '¥3,800 税込',
        catchphrase: '１ヶ月分!',
        link: 'https://hidekicc.base.shop/items/86337630'
    },
    {
        id: 4,
        name: 'ブラジル 500g 中煎 カルモ',
        image: '/images/menu/coffee4-500.jpg',
        price: '¥3,800 税込',
        catchphrase: '１ヶ月分!',
        link: 'https://hidekicc.base.shop/items/86337719'
    },
];
